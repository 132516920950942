<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button variant="outline-success" @click="routeToProductSelector()">
          <b-icon icon="plus"></b-icon> NEW REQUEST</b-button
        >
      </template>
    </header-slot>
    <div>
      <template>
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: `${$route.meta.moduleName}-request-pending` }"
            exact
            :active="$route.matched[1].meta.status === 1"
            :link-classes="['px-3', bgTabsNavs]"
            exact-active-class="active"
          >
            PENDING
            <span class="ml-1" v-if="S_COUNTER_PENDING > 0">
              <feather-icon
                icon
                :badge="S_COUNTER_PENDING"
                badge-classes="badge-important"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: `${$route.meta.moduleName}-request-in-process` }"
            exact
            :active="$route.matched[1].meta.status === 2"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
          >
            IN PROCESS
            <span class="ml-1" v-if="S_COUNTER_IN_PROCESS > 0">
              <feather-icon
                icon
                :badge="S_COUNTER_IN_PROCESS"
                badge-classes="badge-important"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: `${$route.meta.moduleName}-request-delivered` }"
            exact
            :active="$route.matched[1].meta.status === 3"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
          >
            DELIVERED
            <!-- <span class="ml-1" v-if="counter > 0">
              <feather-icon
                icon
                :badge="counter"
                badge-classes="badge-important"
              />
            </span> -->
            <span class="ml-1" v-if="S_COUNTER_DELIVERED > 0">
              <feather-icon
                icon
                :badge="S_COUNTER_DELIVERED"
                badge-classes="badge-important"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: `${$route.meta.moduleName}-request-rejected` }"
            exact
            :active="$route.matched[1].meta.status === 4"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
          >
            REJECTED
          </b-nav-item>
        </b-nav>
        <b-card class="border-top-primary border-3 border-table-radius">
          <router-view :key="$route.path" />
        </b-card>
      </template>
    </div>
  </div>
</template>
<script>
import RequestGrid from "@/views/commons/components/request/components/RequestGrid.vue";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  components: {
    "request-grid": RequestGrid,
  },
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    ...mapState({
      S_COUNTER_PENDING: (state) => state.RequestStore.S_COUNTER_PENDING,
      S_COUNTER_IN_PROCESS: (state) => state.RequestStore.S_COUNTER_IN_PROCESS,
      S_COUNTER_DELIVERED: (state) => state.RequestStore.S_COUNTER_DELIVERED,
    }),
  },
  methods: {
    routeToProductSelector() {
      this.$router.push({
        name: `product-request-${this.$route.meta.moduleName}2`,
      });
    },
    async getCounterNotAsigned() {
      const params = {
        moduleId: this.currentUser.modul_id,
      };
      const data = await RequestService.getCounterNotAsigned(params);
      this.counter = data[0].counter;
    },
    ...mapActions({
      A_SET_COUNTERS_DELIVERED: "RequestStore/A_SET_COUNTERS_DELIVERED",
    }),
  },
  created() {
    this.A_SET_COUNTERS_DELIVERED({ moduleId: this.currentUser.modul_id });
  },
};
</script>